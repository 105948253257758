<template>
    
    <SeguimientoConcienciaComponente></SeguimientoConcienciaComponente>
    
    </template>
    
    <script>
    
    import SeguimientoConcienciaComponente from '@/components/conciencia/SeguimientoConcienciaComponente.vue';
        export default {
    
            name : 'NuevoConciencia',
    
            components :{ SeguimientoConcienciaComponente },
    
    
            data() {
                return {
                    parametro : '',
                   // AgregarConcienciaComponente
                }
            }
            
        }
    </script>
    
    <style scoped>
    
    </style>